<template>
  <div class="app">
    <AppHeader fixed>
      <SidebarToggler class="d-lg-none" display="md" mobile />
      <Logo></Logo>
      <SidebarToggler class="d-md-down-none" display="lg" />
      <TopNavBar/>
      <b-navbar-nav class="ml-auto">
        <sys_adminDropdownAccnt/>
      </b-navbar-nav>
    </AppHeader>
    <div class="app-body">
      <AppSidebar fixed>
        <SidebarHeader/>
        <SidebarForm/>
        <SidebarNav :navItems="nav"></SidebarNav>
        <SidebarFooter/>
        <SidebarMinimizer/>
      </AppSidebar>
      <main class="main">
        <Breadcrumb :list="list"/>
        <div class="container-fluid">
          <flash-message autoHide variant="success"></flash-message>
        </div>
        <div class="container-fluid">
          <router-view></router-view>
        </div>
      </main>
      <AppAside fixed>
      </AppAside>
    </div>
    <TheFooter>
      <!--footer-->
      <!-- <div class="">
        <span class="mr-1">Need Help?</span>
        <router-link :to="{ name: 'Support'}">Support</router-link>
      </div> -->
      <div class="ml-auto">
        <span class="mr-1">Powered by</span>
        <a href="https://rtnest.ca">RTNest Inc. &copy; {{CopyrightDate}}</a>
      </div>
    </TheFooter>
  </div>
</template>

<script>
import nav from '@/_sys_admin'
import { Header as AppHeader, SidebarToggler, Sidebar as AppSidebar, SidebarFooter, SidebarForm, SidebarHeader, SidebarMinimizer, SidebarNav, Aside as AppAside, AsideToggler, Footer as TheFooter } from '@coreui/vue'

import Breadcrumb from './BreadCrumbCustom'
import TopNavBar from './TopNavBar'
import sys_adminDropdownAccnt from './sys_adminDropdownAccnt.vue'
import Logo from './Logo'

export default {
  name: 'sys_adminContainer',
  components: {
    AsideToggler,
    AppHeader,
    AppSidebar,
    AppAside,
    TheFooter,
    Logo,
    Breadcrumb,
    sys_adminDropdownAccnt,
    SidebarForm,
    SidebarFooter,
    SidebarToggler,
    SidebarHeader,
    SidebarNav,
    SidebarMinimizer,
    TopNavBar
  },
  data () {
    return {
      nav: nav.items,
      show: 0,
      CopyrightDate:null
    }
  },
  beforeCreate() {
    if(this.$session.get('user') !== 'sys_admin'){
      this.$session.destroy()
      this.flash({ message: 'Unauthorized!', variant: 'danger' });
      this.$router.push('/')
    }
  },
  mounted() {
    this.CopyrightDate = new Date().getFullYear();
    if(this.$session.get('user') === 'admin'){
      this.show = 1;
    }
  },
  computed: {
    name () {
      return this.$route.name
    },
    list () {
      return this.$route.matched.filter((route) => route.name || route.meta.label )
    }
  }
}
</script>
