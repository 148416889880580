export default {
  items: [
    // {
    //   name: 'Dashboard',
    //   url: '/admin/',
    //   icon: 'icon-speedometer',
    // },
    {
      name: 'All Project Managers',
      url: '/sys_admin/wts',
      icon: 'fa fa-users',
    },
    {
      name: 'All Accountants',
      url: '/sys_admin/accountant',
      icon: 'fa fa-users',
    },
    {
      name: 'All Managers ',
      url: '/sys_admin/Manager',
      icon: 'fa fa-users',
    },
    {
      name: 'All WSS',
      url: '/sys_admin/wss',
      icon: 'fa fa-users',
    },
    {
    name: 'All Vendors',
    url: '/sys_admin/vendor',
    icon: 'fa fa-users',
    },
    {
      name: 'All Users',
      icon: 'fa fa-users',
      children: [
        {
          name: "KEC vendor users",
          url: "/sys_admin/all_vendors",
          icon: "fa fa-users",
        }
        ,{
          name: "KEC team users",
          url: '/sys_admin/all_users',
          icon: "fa fa-users",
        }
      ]
    },
  ]
}
